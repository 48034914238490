import React from "react";
import Navbar from "./Navbar";
import Fade from "react-reveal/Fade";

function Header() {
  return (
    <div className="from-[#2B263F] to-[#231E3D] bg-gradient-to-b w-full  px-10 lg:px-28 lg:h-65vh">
      <Navbar className="z-50" />
      <div className="lg:flex lg:pt-8">
        <Fade left cascade>
          <div className="lg:w-[800px] container  xl:pt-24 lg:pt-10 pt-20">
            <h1 className="font-[1000] xl:text-[4.2rem] lg:text-[3rem] md:text-[3.2rem] text-3xl lg:w-[79%] text-white xl:leading-[5rem] md:leading-[4rem]">
              Consulta fácil,{" "}
              <span className="text-transparent bg-clip-text bg-gradient-to-br from-[#ff6f00] to-[#fa862d]">
                {" "}
                rápida e segura{" "}
              </span>
              para você.
            </h1>
            <div className="xl:flex justify-start mt-7">
            <div>
              <a href="https://wa.me/+5511964855941" className="rounded px-7 py-3 bg-[#ff6f00] text-white relative group hover:text-white overflow-hidden c-btn tracking-wider">
                {/* <span className="absolute inset-0 bg-[#fa862d]"></span> */}
                {/* <span className="absolute inset-0 flex justify-center items-center">
                  Conheça nossos Planos
                </span> */}
                Conheça nossos Planos
              </a>
            </div>
              <p className="xl:w-[50%] lg:w-[70%] md:w-[80%] text-[14px] text-gray-400 lg:leading-6 xl:pl-5 xl:pt-0 pt-4 pb-4">
                Acesse informações abrangentes com apenas alguns cliques.
                Com nosso serviço, você pode obter detalhes cruciais usando diferente parâmetros, como CPF, nome, endereço, RG e até mesmo uma placa de veículo.
              </p>
            </div>
          </div>
        </Fade>

        <div className="pr-24 lg:pr-0">
          <Fade right cascade>
            <div className="boy-illustration">
              <img
                src="https://images-ext-1.discordapp.net/external/RmekN5JZQ8gip6yrd9t52hf2k2qHbNRmhScM6el5i2E/https/redequeroimoveis.com.br/wp-content/uploads/2024/05/Logomarca-UniConsultas-Nova.png?format=webp&quality=lossless&width=503&height=473"
                width={600} alt="Boy Experiencing Digital World"
              />
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
}

export default Header;
