import React from 'react';
import Fade from "react-reveal/Fade";
import Ilust01 from '../Images/1.png';
import Ilust02 from '../Images/2.png';
import Ilust03 from '../Images/3.png';
import Ilust04 from '../Images/4.png';
import Ilust05 from '../Images/5.png';
import Ilust06 from '../Images/6.png';


function Companies() {
  return (
    <div className="lg:h-[200px] from-[#1E1732] to-[#211935] bg-gradient-to-r lg:px-28 px-10 pb-10">
      <Fade bottom cascade>
        <p className="text-[#696984] font-semibold text-center lg:pt-8 pt-6 sm:whitespace-normal whitespace-normal">
        Empresas de diversos seguimentos utilizam os nossos serviços
        </p>
        <div className="lg:flex justify-evenly pt-10 grid grid-cols-2 md:grid-cols-3 gap-10">
          <img
            src={Ilust01} width={100} style={{marginTop: -30}}
            alt='Ilust01'
          />
          <img
            src={Ilust02} width={100} style={{marginTop: -30}}
            alt='Ilust02'
          />
          <img
            src={Ilust03} width={100} style={{marginTop: -30}}
            alt='Ilust03'
          />
          <img
            src={Ilust04} width={100} style={{marginTop: -30}}
            alt='Ilust04'
          />
          <img
            src={Ilust05} width={100} style={{marginTop: -30}}
            alt='Ilust05'
          />
          <img
            src={Ilust06} width={100} style={{marginTop: -30}}
            alt='Ilust06'
          />
        </div>
      </Fade>
    </div>
  );
}

export default Companies